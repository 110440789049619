.body {
  font-family: Helvetica, Arial, sans-serif;
}

.container {
  background: url(assets/img/desktop/landing-page-saavik-bg-min.resize.png)
    no-repeat center center fixed;
  background-color: #242e3a;
  height: 100vh;
  display: flex;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.logo-section {
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url(assets/svg/saavik-logo.svg);
  background-repeat: no-repeat;
  background-size: 30%;
  top: 5%;
  left: 5%;
}

.footer {
  display: flex;
  position: fixed;
  bottom: 10%;
  align-items: flex-end;
  padding: 0 5%;
  width: 100%;
  box-sizing: border-box;
}

/* Contact Component */

.contact-description {
  font-size: 1.1em;
  color: #f6f6f6;
  text-align: left;
  line-height: 1.5;
  margin-bottom: 10px;
  width: 70%;
}

.input-area {
  width: 30vw;
  font-size: 1em;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #252f3a;
  font-weight: 500;
}

.input-area ::placeholder {
  font-size: 1em;
  color: #bee9ee;
  text-align: left;
}

.button {
  margin-left: 10px;
  background-color: #252f3a;
  color: white;
  border: none;
  padding: 13px 30px;
  font-size: 1em;
  font-weight: 600;
  border-radius: 4px;
}

/* Social Media Component */

.is-mobile {
  display: none;
}

.icon-groups {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  cursor: pointer;
}

.icon-groups .socialmedia img {
  width: 60px;
  margin-left: 30px;
}

@media only screen and (max-width: 767px) {
  .container {
    background: url(assets/img/mobile/landing-page-mobile-01.png) no-repeat
      center center;
    background-color: #242e3a;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    top: 0;
  }

  .logo-section {
    background-size: 60%;
    top: 4% !important;
    left: 8% !important;
  }

  .footer {
    flex-wrap: wrap;
    bottom: 10px;
  }

  .form {
    flex-direction: row !important;
  }

  .contact {
    width: 100%;
    bottom: 0px;
  }

  .contact-description {
    font-size: 0.9em;
    line-height: 1.5;
    width: 90%;
  }

  .input-area {
    width: 70%;
    box-sizing: border-box;
    font-size: 0.8em;
    padding: 10px;
    box-sizing: border-box;
  }

  .input-area ::placeholder {
    color: #bee9ee;
    box-sizing: border-box;
  }

  .button {
    width: 23%;
    padding: 10px 10px;
    box-sizing: border-box;
    font-size: 0.9em;
    font-weight: 600;
  }

  .is-mobile {
    display: block;
  }

  .icon-groups {
    width: 100%;
    box-sizing: border-box;
    justify-content: space-evenly;
    padding: 20px 0 0;
    cursor: pointer;
  }

  .icon-groups .socialmedia img {
    width: 50px;
    margin-left: 0 !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .container {
    background: url(assets/img/mobile/landing-page-mobile-01.png) no-repeat
      center center fixed;
    background-color: #242e3a;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    top: 0;
  }

  .logo-section {
    background-size: 60%;
  }

  .contact-description {
    font-size: 1.3em;
    line-height: 1.5;
    margin-bottom: 10px;
    width: 85%;
  }

  .input-area ::placeholder {
    color: #bee9ee;
    text-align: left;
  }

  .input-area {
    font-size: 1.3em;
    width: 100%;
    padding: 8px;
    font-weight: 400;
    box-sizing: border-box;
  }

  .button {
    font-size: 1.3em;
    width: 100%;
    padding: 10px 10px;
    box-sizing: border-box;
    margin: 0 !important;
  }

  .is-mobile {
    display: block;
  }

  .icon-groups {
    width: 100%;
    box-sizing: border-box;
    justify-content: space-evenly;
    align-content: space-between;
    padding: 30px;
    cursor: pointer;
    bottom: 4%;
  }
  .icon-groups .socialmedia {
    width: 50px;
  }
  .icon-groups .socialmedia img {
    width: 100%;
  }
}
